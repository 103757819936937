@charset "UTF-8";
.font, body {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,"PingFangSC-Regular", "SourceHanSansSC-Regular", "Microsoft Yahei",SourceHanSansCN-Medium,SourceHanSansCN,ヒラギノ角ゴ Pro W3,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,ＭＳ Ｐゴシック,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important; }

.oneline {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis; }

.svgGray, .loading.wrapper .img {
  fill: #767676; }

.svgRed {
  fill: #e60023; }

html {
  height: 100%; }

body {
  height: 100%;
  overflow-y: scroll; }

.c-globalHeader {
  z-index: 105;
  position: relative; }

.center {
  padding-left: 16px;
  padding-right: 16px; }

@keyframes rotate {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

.rotateAni {
  transform-origin: 50% 50%;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-play-state: running;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-duration: 1.2s; }

button:hover {
  background-color: transparent;
  border-color: transparent;
  color: #fff; }

.el-dialog__headerbtn:focus .el-dialog__close, .el-dialog__headerbtn:hover .el-dialog__close {
  color: #111; }

.scaleY-enter-active, .scaleY-leave-active {
  transition: all 0.5s; }

.scaleY-enter-to {
  height: 80px !important;
  opacity: 1; }

.scaleY-leave-to, .scaleY-enter {
  height: 0 !important;
  opacity: 0; }

svg:focus {
  outline: none; }

.loading.wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .loading.wrapper::before {
    content: " ";
    display: block;
    float: none;
    clear: both; }
