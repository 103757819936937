$color-black: #333;
$color-hover-background: #E2E2E2;
$color-pin-red: #E60023;
$color-pin-blue: #4FA9C5;
$color-vip-red: #FF4D4D;
$color-vip-black: #333;
$color-gray: #8d8d8d;
$color-gray2: #f0f0f0;
.font{
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,"PingFangSC-Regular", "SourceHanSansSC-Regular", "Microsoft Yahei",SourceHanSansCN-Medium,SourceHanSansCN,ヒラギノ角ゴ Pro W3,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,ＭＳ Ｐゴシック,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important;
}
.oneline{
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
}
.svgGray{
  fill:#767676;
}
.svgRed{
  fill: #e60023;
}
